<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>code </label>
          <input type="text" :class="errors.code?'form-control is-invalid':'form-control'"
                 v-model="form.code">

          <div class="invalid-feedback" v-if="errors.code">
            <template v-for=" error in errors.code"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>type </label>
          <input type="text" :class="errors.type?'form-control is-invalid':'form-control'"
                 v-model="form.type">

          <div class="invalid-feedback" v-if="errors.type">
            <template v-for=" error in errors.type"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>marque </label>
          <input type="text" :class="errors.marque?'form-control is-invalid':'form-control'"
                 v-model="form.marque">

          <div class="invalid-feedback" v-if="errors.marque">
            <template v-for=" error in errors.marque"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>modele </label>
          <input type="text" :class="errors.modele?'form-control is-invalid':'form-control'"
                 v-model="form.modele">

          <div class="invalid-feedback" v-if="errors.modele">
            <template v-for=" error in errors.modele"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>generation </label>
          <input type="text" :class="errors.generation?'form-control is-invalid':'form-control'"
                 v-model="form.generation">

          <div class="invalid-feedback" v-if="errors.generation">
            <template v-for=" error in errors.generation"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>serie </label>
          <input type="text" :class="errors.serie?'form-control is-invalid':'form-control'"
                 v-model="form.serie">

          <div class="invalid-feedback" v-if="errors.serie">
            <template v-for=" error in errors.serie"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>valeur </label>
          <input type="text" :class="errors.valeur?'form-control is-invalid':'form-control'"
                 v-model="form.valeur">

          <div class="invalid-feedback" v-if="errors.valeur">
            <template v-for=" error in errors.valeur"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>moteur </label>
          <input type="text" :class="errors.moteur?'form-control is-invalid':'form-control'"
                 v-model="form.moteur">

          <div class="invalid-feedback" v-if="errors.moteur">
            <template v-for=" error in errors.moteur"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>poids </label>
          <input type="text" :class="errors.poids?'form-control is-invalid':'form-control'"
                 v-model="form.poids">

          <div class="invalid-feedback" v-if="errors.poids">
            <template v-for=" error in errors.poids"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>creat_by </label>
          <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 v-model="form.creat_by">

          <div class="invalid-feedback" v-if="errors.creat_by">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>identifiants_sadge </label>
          <input type="text" :class="errors.identifiants_sadge?'form-control is-invalid':'form-control'"
                 v-model="form.identifiants_sadge">

          <div class="invalid-feedback" v-if="errors.identifiants_sadge">
            <template v-for=" error in errors.identifiants_sadge"> {{ error[0] }}</template>

          </div>
        </div>


      </div>

      <button type="submit" class="btn btn-primary">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'CreateVehicules',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        code: "",

        type: "",

        marque: "",

        modele: "",

        generation: "",

        serie: "",

        valeur: "",

        moteur: "",

        poids: "",

        creat_by: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",

        identifiants_sadge: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/vehicules', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        code: "",
        type: "",
        marque: "",
        modele: "",
        generation: "",
        serie: "",
        valeur: "",
        moteur: "",
        poids: "",
        creat_by: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        identifiants_sadge: "",
      }
    }
  }
}
</script>
